import { IBook, BookDto } from './book'
import { IState, StateDto } from './state'
import { IContract, ContractDto } from './contract'
import { ITechnicalDetails, TechnicalDetailsDto } from './technical-details'
import {
  IDistributionDetails,
  DistributionDetailsDto,
} from './distribution-details'
import { AuthoringProgressDto, IAuthoringProgress } from './authoring-progress'
import { IUser, UserDto } from './user'
import { BaseDto, IBaseDto } from './persistable'
import { Nullable, NullableArray } from './utils'

export interface IThirdPartyProcessingDates {
  delivery: Nullable<Date>
  expectedCompletion: Nullable<Date>
  actualCompletion: Nullable<Date>
  notes: Nullable<String>
}

export interface IRuleSet {
  title: Nullable<Boolean>
  subtitle: Nullable<Boolean>
  tableOfContents: Nullable<Boolean>
  oneFile: Nullable<Boolean>
  capitalHeaders: Nullable<Boolean>
  glossary: Nullable<Boolean>
  index: Nullable<Boolean>
  backText: Nullable<Boolean>
  bibliography: Nullable<Boolean>
  catalogText: Nullable<Boolean>
  photos: Nullable<Boolean>
  equations: Nullable<Boolean>
  diagrams: Nullable<Boolean>
  tables: Nullable<Boolean>
}

export interface IBookPublication extends IBaseDto {
  seqId: Nullable<number>
  book: Nullable<IBook>
  currentState: Nullable<IState>
  previousStates: NullableArray<IState>
  contracts: NullableArray<IContract>
  technicalDetails: NullableArray<ITechnicalDetails>
  distributionDetails: NullableArray<IDistributionDetails>
  authoringProgress: NullableArray<IAuthoringProgress>
  watchers: NullableArray<IUser>
  manuscriptDeliveryEstimatedDate: Nullable<Date>
  finalReceivedDate: Nullable<Date>
  desiredReleaseDate: Nullable<Date>
  publicationDate: Nullable<Date>
  publicationComments: Nullable<String>
  resolution: Nullable<Boolean>
  resolutionDate: Nullable<Date>
  resolutionComments: Nullable<string>
  isDistributable: Nullable<Boolean>
  verificationComments: Nullable<String>
  checkerDates: Nullable<IThirdPartyProcessingDates>
  paginationDates: Nullable<IThirdPartyProcessingDates>
  verificationRuleSet: Nullable<IRuleSet>
  updatedAt: Readonly<Date>
}

export class ThirdPartyProcessingDatesDto
  implements IThirdPartyProcessingDates {
  delivery: Nullable<Date>
  expectedCompletion: Nullable<Date>
  actualCompletion: Nullable<Date>
  notes: Nullable<String>
  className? = 'ThirdPartyProcessingDates'

  constructor(init?: Partial<ThirdPartyProcessingDatesDto>) {
    if (init) {
      Object.assign(this, init)
    }
  }
}

export class RuleSetDto implements IRuleSet {
  title: Nullable<Boolean>
  subtitle: Nullable<Boolean>
  tableOfContents: Nullable<Boolean>
  oneFile: Nullable<Boolean>
  capitalHeaders: Nullable<Boolean>
  glossary: Nullable<Boolean>
  index: Nullable<Boolean>
  backText: Nullable<Boolean>
  bibliography: Nullable<Boolean>
  catalogText: Nullable<Boolean>
  photos: Nullable<Boolean>
  equations: Nullable<Boolean>
  diagrams: Nullable<Boolean>
  tables: Nullable<Boolean>
  className? = 'RuleSet'

  constructor(init?: Partial<RuleSetDto>) {
    if (init) {
      Object.assign(this, init)
    }
  }
}

export class BookPublicationDto extends BaseDto implements IBookPublication {
  seqId: Nullable<number>
  book: Nullable<BookDto>
  currentState: Nullable<StateDto>
  previousStates: NullableArray<StateDto>
  contracts: NullableArray<ContractDto>
  technicalDetails: NullableArray<TechnicalDetailsDto>
  distributionDetails: NullableArray<DistributionDetailsDto>
  authoringProgress: NullableArray<AuthoringProgressDto>
  watchers: NullableArray<UserDto>
  manuscriptDeliveryEstimatedDate: Nullable<Date>
  finalReceivedDate: Nullable<Date>
  desiredReleaseDate: Nullable<Date>
  publicationDate: Nullable<Date>
  publicationComments: Nullable<String>
  resolution: Nullable<Boolean>
  resolutionDate: Nullable<Date>
  resolutionComments: Nullable<string>
  isDistributable: Nullable<Boolean>
  verificationComments: Nullable<String>
  checkerDates: Nullable<ThirdPartyProcessingDatesDto>
  paginationDates: Nullable<ThirdPartyProcessingDatesDto>
  verificationRuleSet: Nullable<RuleSetDto>
  updatedAt: Readonly<Date>
  className? = 'BookPublication'

  constructor(init?: Partial<BookPublicationDto>) {
    super()
    if (init) {
      const {
        book,
        currentState,
        previousStates,
        contracts,
        technicalDetails,
        distributionDetails,
        authoringProgress,
        watchers,
        checkerDates,
        paginationDates,
        verificationRuleSet,
        ...rest
      } = init
      Object.assign(this, rest)
      this.book = book && new BookDto(book)
      this.currentState = currentState && new StateDto(currentState)
      this.previousStates = previousStates?.map(el => el && new StateDto(el))
      this.contracts = contracts?.map(el => el && new ContractDto(el))
      this.technicalDetails = technicalDetails?.map(
        el => el && new TechnicalDetailsDto(el)
      )
      this.distributionDetails = distributionDetails?.map(
        el => el && new DistributionDetailsDto(el)
      )
      this.authoringProgress = authoringProgress?.map(
        el => el && new AuthoringProgressDto(el)
      )
      this.watchers = watchers?.map(el => el && new UserDto(el))
      this.checkerDates =
        checkerDates && new ThirdPartyProcessingDatesDto(checkerDates)
      this.paginationDates =
        paginationDates && new ThirdPartyProcessingDatesDto(paginationDates)
      this.verificationRuleSet =
        verificationRuleSet && new RuleSetDto(verificationRuleSet)
    }
  }
}
