import { IBookProducer, BookProducerDto } from './book-producer'
import { BaseDto, IBaseDto } from './persistable'
import { Nullable } from './utils'

export interface ITechnicalDetails extends IBaseDto {
  sentDate: Nullable<Date>
  bookProducer: Nullable<IBookProducer>
}

export class TechnicalDetailsDto extends BaseDto implements ITechnicalDetails {
  sentDate: Nullable<Date>
  bookProducer: Nullable<BookProducerDto>
  className? = 'TechnicalDetails'

  constructor(init?: Partial<TechnicalDetailsDto>) {
    super()
    if (init) {
      const { bookProducer, ...rest } = init
      Object.assign(this, rest)
      this.bookProducer = bookProducer && new BookProducerDto(bookProducer)
    }
  }
}
