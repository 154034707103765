// Single point of exporting all of the modules defined in the source files.
import {
  IDeliverable,
  IAuthoringProgress,
  DeliverableDto,
  AuthoringProgressDto,
} from './authoring-progress'
import { IBook, BookDto, WrittenFormat } from './book'
import { IBookCategory, BookCategoryDto } from './book-category'
import { IBookProducer, BookProducerDto, Role } from './book-producer'
import {
  IBookPublication,
  BookPublicationDto,
  ThirdPartyProcessingDatesDto,
  RuleSetDto,
  IThirdPartyProcessingDates,
  IRuleSet,
} from './book-publication'
import { IComment, CommentDto } from './comment'
import { IContract, ContractDto } from './contract'
import {
  IDistributionDetails,
  DistributionDetailsDto,
} from './distribution-details'
import {
  IInstitutionDetails,
  InstitutionDetailsDto,
} from './institution-details'
import { IAddress, IPerson, AddressDto, PersonDto } from './person'
import { IState, StateDto } from './state'
import { IStep, StepDto } from './step'
import { ITechnicalDetails, TechnicalDetailsDto } from './technical-details'
import { IUser, UserDto, AuthRole } from './user'
import { Nullable, NullableArray } from './utils'
import { ISeries, SeriesDto } from './series'

export {
  IDeliverable,
  IAuthoringProgress,
  IBook,
  IBookCategory,
  IBookProducer,
  IBookPublication,
  IComment,
  IContract,
  IInstitutionDetails,
  IDistributionDetails,
  IAddress,
  IPerson,
  ISeries,
  IState,
  IStep,
  ITechnicalDetails,
  IUser,
  Role,
  WrittenFormat,
  IThirdPartyProcessingDates,
  IRuleSet,
  AuthRole,
}

export default {
  DeliverableDto,
  AuthoringProgressDto,
  BookDto,
  BookCategoryDto,
  BookProducerDto,
  BookPublicationDto,
  CommentDto,
  ContractDto,
  InstitutionDetailsDto,
  DistributionDetailsDto,
  AddressDto,
  PersonDto,
  SeriesDto,
  StateDto,
  StepDto,
  TechnicalDetailsDto,
  UserDto,
  ThirdPartyProcessingDatesDto,
  RuleSetDto,
}

export { Nullable, NullableArray }
