import { BookProducerDto, IBookProducer } from './book-producer'
import {
  IInstitutionDetails,
  InstitutionDetailsDto,
} from './institution-details'
import { BaseDto, IBaseDto } from './persistable'
import { Nullable, NullableArray } from './utils'

export interface IDistributionDetails extends IBaseDto {
  institutionDetails: Nullable<IInstitutionDetails>
  course: Nullable<String>
  numCopies: Nullable<Number>
  tutors: NullableArray<IBookProducer>
}

export class DistributionDetailsDto extends BaseDto
  implements IDistributionDetails {
  institutionDetails: Nullable<InstitutionDetailsDto>
  course: Nullable<String>
  numCopies: Nullable<Number>
  tutors: NullableArray<BookProducerDto>
  className? = 'DistributionDetails'

  constructor(init?: Partial<DistributionDetailsDto>) {
    super()
    if (init) {
      const { institutionDetails, tutors, ...rest } = init
      Object.assign(this, rest)
      this.institutionDetails =
        institutionDetails && new InstitutionDetailsDto(institutionDetails)

      this.tutors = tutors?.map(el => el && new BookProducerDto(el))
    }
  }
}
