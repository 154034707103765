import { IPerson, AddressDto } from './person'
import { BaseDto, IBaseDto } from './persistable'
import { Nullable, NullableArray } from './utils'

export enum AuthRole {
  User = 'User',
  Administrator = 'Administrator',
}

export interface IUser extends IPerson, IBaseDto {
  username: Nullable<String>
  password: Nullable<String>
  authRoles: NullableArray<AuthRole>
  resetPasswordToken: Nullable<String>
  resetPasswordExpires: Nullable<Number>
  accountExpires: Nullable<Number>
  locked: Nullable<Boolean>
}

export class UserDto extends BaseDto implements IUser {
  username: Nullable<String>
  password: Nullable<String>
  authRoles: NullableArray<AuthRole>
  resetPasswordToken: Nullable<String>
  resetPasswordExpires: Nullable<Number>
  accountExpires: Nullable<Number>
  locked: Nullable<Boolean>
  name: Nullable<String>
  surname: Nullable<String>
  email: NullableArray<String>
  phone: Nullable<String>
  address: Nullable<AddressDto>
  className? = 'User'

  constructor(init?: Partial<UserDto>) {
    super()
    if (init) {
      const { address, ...rest } = init
      Object.assign(this, rest)
      this.address = address && new AddressDto(address)
    }
  }
}
