(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory();
	else if(typeof define === 'function' && define.amd)
		define("gutenberg-bpm-common", [], factory);
	else if(typeof exports === 'object')
		exports["gutenberg-bpm-common"] = factory();
	else
		root["gutenberg-bpm-common"] = factory();
})(global, function() {
return 