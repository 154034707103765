import { BaseDto, IBaseDto } from './persistable'
import { Nullable } from './utils'

export interface ISeries extends IBaseDto {
  name: Nullable<String>
}

export class SeriesDto extends BaseDto implements ISeries {
  name: Nullable<String>
  className? = 'Series'

  constructor(init?: Partial<SeriesDto>) {
    super()
    if (init) {
      Object.assign(this, init)
    }
  }
}
