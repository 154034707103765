import { IPerson, AddressDto } from './person'
import { BaseDto, IBaseDto } from './persistable'
import { Nullable, NullableArray } from './utils'
import { IInstitutionDetails } from '.'
import { InstitutionDetailsDto } from './institution-details'

export enum Role {
  Author = 'author',
  Editor = 'editor',
  Tutor = 'tutor',
  Other = 'other',
}

export interface IBookProducer extends IPerson, IBaseDto {
  roles: Nullable<Role>[]
  institutionDetails: Nullable<IInstitutionDetails>
}

export class BookProducerDto extends BaseDto implements IBookProducer {
  name: Nullable<String>
  surname: Nullable<String>
  email: NullableArray<String>
  phone: Nullable<String>
  address: Nullable<AddressDto>
  roles: Nullable<Role>[]
  institutionDetails: Nullable<InstitutionDetailsDto>
  className? = 'BookProducer'

  constructor(init?: Partial<BookProducerDto>) {
    super()
    if (init) {
      const { institutionDetails, ...rest } = init
      Object.assign(this, rest)
      this.institutionDetails =
        institutionDetails && new InstitutionDetailsDto(institutionDetails)
    }
  }
}
