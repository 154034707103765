import { IUser, UserDto } from './user'
import { BaseDto, IBaseDto } from './persistable'
import { Nullable } from './utils'

export interface IComment extends IBaseDto {
  message: Nullable<String>
  commenter: Nullable<IUser>
  createdAt: Readonly<Date>
  updatedAt: Readonly<Date>
}

export class CommentDto extends BaseDto implements IComment {
  message: Nullable<String>
  commenter: Nullable<UserDto>
  createdAt: Readonly<Date>
  updatedAt: Readonly<Date>
  className? = 'Comment'

  constructor(init?: Partial<CommentDto>) {
    super()
    if (init) {
      const { commenter, ...rest } = init
      Object.assign(this, rest)
      this.commenter = commenter && new UserDto(commenter)
    }
  }
}
