import { IBookCategory, BookCategoryDto } from './book-category'
import { IBookProducer, BookProducerDto } from './book-producer'
import { BaseDto, IBaseDto } from './persistable'
import { ISeries, SeriesDto } from './series'
import { Nullable, NullableArray } from './utils'

export enum WrittenFormat {
  Word = 'Word',
  WordMathType = 'WordMathType',
  WordEquator = 'WordEquator',
  Latex = 'Latex',
  Handwritten = 'Handwritten',
}

export interface IBook extends IBaseDto {
  title: Nullable<String>
  category: NullableArray<IBookCategory>
  authors: NullableArray<IBookProducer>
  editors: NullableArray<IBookProducer>
  numPages: Nullable<Number>
  oneFile: Nullable<Boolean>
  colour: Nullable<Boolean>
  equations: Nullable<Boolean>
  equationsComments: Nullable<String>
  tables: Nullable<Boolean>
  diagrams: Nullable<Boolean>
  photos: Nullable<Boolean>
  picturesCopyRight: Nullable<Boolean>
  picturesCopyRightComments: Nullable<String>
  textCopyRight: Nullable<Boolean>
  technical: Nullable<boolean>
  writtenFormat: Nullable<String | WrittenFormat>
  dimensions: Nullable<String>
  dimensionsComments: Nullable<String>
  series: Nullable<ISeries>
}

export class BookDto extends BaseDto implements IBook {
  title: Nullable<String>
  category: NullableArray<BookCategoryDto>
  authors: NullableArray<BookProducerDto>
  editors: NullableArray<BookProducerDto>
  numPages: Nullable<Number>
  oneFile: Nullable<Boolean>
  colour: Nullable<Boolean>
  equations: Nullable<Boolean>
  equationsComments: Nullable<String>
  tables: Nullable<Boolean>
  diagrams: Nullable<Boolean>
  photos: Nullable<Boolean>
  picturesCopyRight: Nullable<Boolean>
  picturesCopyRightComments: Nullable<String>
  textCopyRight: Nullable<Boolean>
  technical: Nullable<boolean>
  writtenFormat: Nullable<String | WrittenFormat>
  dimensions: Nullable<String>
  dimensionsComments: Nullable<String>
  series: Nullable<SeriesDto>
  className? = 'Book'

  constructor(init?: Partial<BookDto>) {
    super()
    if (init) {
      const { category, authors, editors, series, ...rest } = init
      Object.assign(this, rest)
      this.category = category?.map(el => el && new BookCategoryDto(el))
      this.authors = authors?.map(el => el && new BookProducerDto(el))
      this.editors = editors?.map(el => el && new BookProducerDto(el))
      this.series = series && new SeriesDto(series)
    }
  }
}
