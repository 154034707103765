export const ErrorCodes = {
  NotFound: 'ERR_NOT_FOUND',
  AlreadyExists: 'ERR_ALREADY_EXISTS',
  InvalidData: 'ERR_INVALID_DATA',
  TypeMismatch: 'ERR_TYPE_MISMATCH',
  IdMismatch: 'ERR_ID_MISMATCH',
  ValidationFailed: 'ERR_VALIDATION',
  MalformedRequest: 'ERR_MALFORMED_REQUEST',
  NotSupported: 'ERR_NOT_SUPPORTED',

  AuthenticationFailed: 'ERR_AUTH',
  NotAuthorized: 'ERR_NOT_ALLOWED',
  UserAlreadyExists: 'ERR_USER_ALREADY_EXISTS',
  UserUnknown: 'ERR_USER_UNKNOWN',
  UserRegistrationFailed: 'ERR_USER_REGISTRATION',
  PasswordResetFailed: 'ERR_PASSWORD_RESET',
  ResetTokenExpired: 'ERR_RESET_TOKEN_EXPIRED',

  Internal: 'ERR_INTERNAL',
  Unknown: 'ERR_UNKNOWN',
}
