import { Nullable, NullableArray } from './utils'

export interface IAddress {
  street: Nullable<String>
  number: Nullable<String>
  city: Nullable<String>
  postcode: Nullable<String>
}

export interface IPerson {
  name: Nullable<String>
  surname: Nullable<String>
  address: Nullable<IAddress>
  phone: Nullable<String>
  email: NullableArray<String>
}

export class AddressDto implements IAddress {
  street: Nullable<String>
  number: Nullable<String>
  city: Nullable<String>
  postcode: Nullable<String>
  className? = 'Address'

  constructor(init?: Partial<AddressDto>) {
    if (init) {
      Object.assign(this, init)
    }
  }
}

export class PersonDto implements IPerson {
  name: Nullable<String>
  surname: Nullable<String>
  address: Nullable<IAddress>
  phone: Nullable<String>
  email: NullableArray<String>

  constructor(init?: Partial<PersonDto>) {
    if (init) {
      const { address, ...rest } = init
      Object.assign(this, rest)
      this.address = address && new AddressDto(address)
    }
  }
}
