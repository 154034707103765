import { IDataObject } from '@hypernetica/json-api'
import _ from 'lodash'
import { ForceArray } from './utils'

export interface IBaseDto {
  id?: string
}

export class BaseDto implements IBaseDto {
  // Id is not optional here, because IDataObject has it required. Of course, that's not the case in runtime
  // since ui can send a post without filling the id field. Will change when IDataObject in json-api changes
  id?: string
  className?: string
  static toIDataObject<T extends BaseDto>(instance: T): IDataObject {
    const retObj: IDataObject = {
      id: instance.id!,
      type: instance.className!,
    }
    const relationships: any = {}
    const attributes: any = {}

    _.forEach(_.omit(instance, ['id', 'className']), (value, key) => {
      if (ForceArray(value)[0] instanceof BaseDto) {
        if (_.isArray(value)) {
          relationships[key] = value.map(el => BaseDto.toIDataObject(el))
        } else {
          relationships[key] = BaseDto.toIDataObject(value as any)
        }
      } else {
        attributes[key] = value
      }
    })

    if (!_.isEmpty(relationships)) {
      retObj.relationships = relationships
    }

    if (!_.isEmpty(attributes)) {
      retObj.attributes = attributes
    }
    return retObj
  }

  static fromIDataObject<T extends BaseDto>(
    this: new (init?) => T,
    instance: IDataObject
  ) {
    const recurse = value => {
      let relationships = {}
      _.forEach(value.relationships, (value, key) => {
        if (_.isArray(value)) {
          relationships[key] = value.map(el => recurse(el))
        } else {
          relationships[key] = recurse(value)
        }
      })

      return {
        id: value.id,
        ...value.attributes,
        ...relationships,
      }
    }

    return new this(recurse(instance))
  }
}
