import { IBookProducer, BookProducerDto } from './book-producer'
import { IBaseDto, BaseDto } from './persistable'
import { Nullable, NullableArray } from './utils'

export interface IDeliverable {
  sequenceNo: Nullable<Number>
  description: Nullable<string>
  expectedDeliveryDate: Nullable<Date>
  deliveryDate: Nullable<Date>
}

export interface IAuthoringProgress extends IBaseDto {
  totalChunkNo: Nullable<Number>
  deliverables: NullableArray<IDeliverable>
  bookProducer: Nullable<IBookProducer>
}

export class DeliverableDto implements IDeliverable {
  sequenceNo: Nullable<Number>
  description: Nullable<string>
  expectedDeliveryDate: Nullable<Date>
  deliveryDate: Nullable<Date>
  className? = 'Deliverable'

  constructor(init?: Partial<DeliverableDto>) {
    if (init) {
      Object.assign(this, init)
    }
  }
}

export class AuthoringProgressDto extends BaseDto
  implements IAuthoringProgress {
  totalChunkNo: Nullable<Number>
  deliverables: NullableArray<DeliverableDto>
  bookProducer: Nullable<BookProducerDto>
  className? = 'AuthoringProgress'

  constructor(init?: Partial<AuthoringProgressDto>) {
    super()
    if (init) {
      const { deliverables, bookProducer, ...rest } = init
      Object.assign(this, rest)
      this.deliverables = deliverables?.map(el => el && new DeliverableDto(el))
      this.bookProducer = bookProducer && new BookProducerDto(bookProducer)
    }
  }
}
