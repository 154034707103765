import { IBookProducer, BookProducerDto } from './book-producer'
import { BaseDto, IBaseDto } from './persistable'
import { Nullable } from './utils'

export interface IContract extends IBaseDto {
  draftSentDate: Nullable<Date>
  draftApprovalDate: Nullable<Date>
  finalSentDate: Nullable<Date>
  signDate: Nullable<Date>
  startDate: Nullable<Date>
  endDate: Nullable<Date>
  bookProducer: Nullable<IBookProducer>
}

export class ContractDto extends BaseDto implements IContract {
  draftSentDate: Nullable<Date>
  draftApprovalDate: Nullable<Date>
  finalSentDate: Nullable<Date>
  signDate: Nullable<Date>
  startDate: Nullable<Date>
  endDate: Nullable<Date>
  bookProducer: Nullable<BookProducerDto>
  className? = 'Contract'

  constructor(init?: Partial<ContractDto>) {
    super()
    if (init) {
      const { bookProducer, ...rest } = init
      Object.assign(this, rest)
      this.bookProducer = bookProducer && new BookProducerDto(bookProducer)
    }
  }
}
