import { UserDto } from './user'
import { IStep, StepDto, IAssignable } from './step'
import { BaseDto, IBaseDto } from './persistable'
import { Nullable, NullableArray } from './utils'

export interface IState extends IAssignable, IBaseDto {
  steps: NullableArray<IStep>
}

export class StateDto extends BaseDto implements IState {
  readonly code: Number
  phaseStartDate: Nullable<Date>
  phaseEndDate: Nullable<Date>
  assignedTo: NullableArray<UserDto>
  signedOffBy: Nullable<UserDto>
  steps: NullableArray<StepDto>
  className? = 'State'

  constructor(init?: Partial<StateDto>) {
    super()
    if (init) {
      const { assignedTo, signedOffBy, steps, ...rest } = init
      Object.assign(this, rest)
      this.assignedTo = assignedTo?.map(el => el && new UserDto(el))
      this.signedOffBy = signedOffBy && new UserDto(signedOffBy)
      this.steps = steps?.map(el => el && new StepDto(el))
    }
  }
}
