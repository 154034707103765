import { InvalidDataError, DomainError } from '@hypernetica/error'

interface IJsonApiObject {
  id: string
  type: string
  attributes?: {
    [fieldName: string]: any
  }
  relationships?: {
    [fieldName: string]: {
      data: IJsonApiObject | IJsonApiObject[]
    }
  }
  links?: {
    related: {
      rel: string
      href: string
      params: {
        type: string[]
        method: string
        expectedAttributes: string[]
      }
    }
  }
}

interface IJsonApiWrapper {
  data?: IJsonApiObject | IJsonApiObject[]
  errors?: IJsonApiError | IJsonApiError[]
  included?: IJsonApiObject | IJsonApiObject[]
}

/**
 * Represents an object to serialize.
 * At minimum it needs to include ID and Type
 */
export interface IDataObject {
  /** The resource ID */
  id: string

  /** The resource type */
  type: string

  /** This will contain all the object attributes except ID*/
  attributes?: { [fieldName: string]: any }

  /** Relationships to other IDataObjects */
  relationships?: { [fieldName: string]: IDataObject | IDataObject[] }

  /** State management information */
  stateInfo?: {
    /** The status handling URL */
    url: string
    /** The states to which the entity may transition to. */
    allowedNextStates: string[]
    /** A list of attributes to send along with the state transition request */
    expectedAttributes: string[]
  }
}

interface IJsonApiError {
  // HTTP status code
  status: string

  // Error code
  code: string

  // Short summary
  title: string

  // Detailed summary
  detail?: string
}

export class SerializeOptions {
  /** Children we want to include in the serialized response.
  These will be serialized as "related" and "included" properties.
  */
  includeChildren?: string[]
}

export class JsonApi {
  /** @deprecated Please use serializeError() */
  public static wrapError(errors: Error[]): string {
    console.warn('Deprecated. Please use JsonApi.serializeError() instead.')
    return JsonApi.serializeError(errors)
  }

  /** Wraps an error into a Json.API Error envelope */
  public static serializeError(errors: Error[]): string {
    const wrappedErrors: IJsonApiError[] = []
    for (const error of errors) {
      wrappedErrors.push({
        code: error instanceof DomainError ? error.code : 'ERR_GENERAL',
        status: error instanceof DomainError ? error.status.toString() : '500',
        title: error.message,
        detail: process.env.NODE_ENV === 'development' ? error.stack : '',
      })
    }
    const envelope: IJsonApiWrapper = {
      errors: wrappedErrors,
    }

    return JSON.stringify(envelope)
  }

  /** Deserializes (parses) an incoming error into one or more DomainErrors.
      @param receivedError The JSON.API response body containing the error */
  public static deserializeError(receivedError: string): DomainError[] {
    let received: IJsonApiWrapper
    try {
      received = JSON.parse(receivedError)
    } catch (err) {
      throw new InvalidDataError(err.message)
    }

    // Add other checks here...

    if (
      undefined === received.errors ||
      (Array.isArray(received.errors) && 0 === received.errors.length)
    ) {
      throw new InvalidDataError(
        'No errors were found in response. Cannot deserialize.'
      )
    }

    const errors: DomainError[] = []

    for (const err of ForceArray(received.errors)) {
      errors.push(new DomainError(err.status, err.code, err.title))
    }

    return errors
  }

  // ------------------ SERIALIZATION ------------------

  /**
   * Serializes an object to a JSON.API response
   * @param  {any|any[]} data The object to serialize.
   * @param  {SerializeOptions} options Options to pass to the serializer
   */
  public static serialize(
    data: IDataObject | IDataObject[],
    options?: SerializeOptions
  ): string | undefined {
    const envelope: IJsonApiWrapper = {}
    //const serializedResources: ApiData[] = []
    const serializedResources: IJsonApiObject[] = []

    // "Data" property
    const arraySafeData: IDataObject[] = ForceArray(data)
    for (const item of arraySafeData) {
      const dataItem: IJsonApiObject = this.transformOutgoingData(item, false)
      serializedResources.push(dataItem)
    }

    envelope.data = serializedResources

    // Add included resources if specified.
    if (options?.includeChildren) {
      const includedResourceList: IDataObject[] = []
      for (const item of arraySafeData) {
        JsonApi.getIncludedResources(
          item,
          '',
          options.includeChildren,
          includedResourceList
        )
      }

      const includedResources: IJsonApiObject[] = []
      for (const resource of includedResourceList) {
        includedResources.push(JsonApi.transformOutgoingData(resource, false))
      }
      if (0 < includedResources.length) {
        envelope.included = includedResources
      }
    }

    const serializedData: string = JSON.stringify(envelope) //, filterIgnored)

    return serializedData
  }

  /** Appends related sources to the alreadyIncluded list */
  private static getIncludedResources(
    item: IDataObject,
    path: string,
    includeList: string[],
    alreadyIncluded: IDataObject[]
  ) {
    if (undefined === item.relationships) {
      return
    }

    for (const includeField of includeList) {
      for (const relatedField in item.relationships) {
        if (includeField === path.concat(relatedField)) {
          // We have a field with a 'relationship' property that matches the include list
          // and need to recurse into the entities it specifies.
          const relatedEntities: IDataObject[] = ForceArray(
            item.relationships[relatedField]
          )
          for (const relatedEntity of relatedEntities) {
            if (
              !alreadyIncluded.find(
                o => o.type === relatedEntity.type && o.id === relatedEntity.id
              )
            ) {
              alreadyIncluded.push(relatedEntity)
              JsonApi.getIncludedResources(
                relatedEntity,
                path.concat(relatedField + '.'),
                includeList,
                alreadyIncluded
              )
            }
          }
        }
      }
    }
  }

  /** 
  Transforms the object passed 
  into a JSON.API 'data' node (dataNode) --using a model--
  Any relationships are passed on, to be included as resources in the response
  @param dataObject The object to serialize
  @param skipDetails Just serialize ID and type.
  */
  private static transformOutgoingData(
    dataObject: IDataObject,
    skipDetails: boolean
  ): IJsonApiObject {
    const jsonApiNode: IJsonApiObject = {
      id: dataObject.id,
      type: dataObject.type,
      attributes: skipDetails ? undefined : dataObject.attributes,
    }

    if (!skipDetails && undefined !== dataObject.relationships) {
      jsonApiNode.relationships = {}
      for (const fieldName in dataObject.relationships) {
        let relEntities: IJsonApiObject | IJsonApiObject[] = []
        const relatedEntities = ForceArray(dataObject.relationships[fieldName])
        for (const entity of relatedEntities) {
          relEntities.push(JsonApi.transformOutgoingData(entity, true))
        }
        if (!Array.isArray(dataObject.relationships[fieldName])) {
          relEntities = relEntities[0]
        }
        jsonApiNode.relationships[fieldName] = {
          data: relEntities,
        }
      }
    }

    if (dataObject.stateInfo) {
      jsonApiNode.links = {
        related: {
          rel: 'status',
          href:
            (!dataObject.stateInfo.url.startsWith('/') ? '/' : '') +
            dataObject.stateInfo.url,
          params: {
            type: dataObject.stateInfo.allowedNextStates,
            method: 'POST',
            expectedAttributes: dataObject.stateInfo.expectedAttributes,
          },
        },
      }
    }

    return jsonApiNode
  }

  // ------------------ DESERIALIZATION ------------------

  /** Deserializes a JSON.API message to an IDataObject
   */
  public static deserialize(receivedData: string): IDataObject | IDataObject[] {
    const receivedObject: {
      data: IJsonApiObject[]
      included?: IJsonApiObject[]
    } = this.validateJsonData(receivedData)

    const dataObject: IDataObject[] = this.transformIncomingObject(
      receivedObject.data
    )

    if (receivedObject.included) {
      const includedResources: IDataObject[] = this.transformIncomingObject(
        receivedObject.included
      )

      // Hydrate relationships: add "attributes" and relationships to other idataobjects recursively, if present
      for (const dataItem of dataObject) {
        this.hydrateObject(dataItem, includedResources)
      }
    }

    if (dataObject.length === 1) {
      return dataObject[0]
    }
    return dataObject
  }

  private static hydrateObject(item: IDataObject, pool: IDataObject[]) {
    for (const relField in item.relationships) {
      // For every value of the relationship field
      const relValue = item.relationships[relField]
      // search includedResources for items with this id & type and deserialize them
      if (Array.isArray(relValue)) {
        for (let i = 0; i < relValue.length; i++) {
          const res = pool.find(
            o => o.id === relValue[i].id && o.type === relValue[i].type
          )
          if (res) {
            this.hydrateObject(res, pool)
            relValue[i] = res
          }
        }
      } else {
        const res = pool.find(
          o => o.id === relValue.id && o.type === relValue.type
        )
        if (res) {
          this.hydrateObject(res, pool)
          relValue.attributes = res.attributes
          if (res.relationships) relValue.relationships = res.relationships
        }
      }
    }
  }

  private static validateJsonData(
    data: string
  ): { data: IJsonApiObject[]; included?: IJsonApiObject[] } {
    let received: IJsonApiWrapper
    try {
      received = JSON.parse(data)
    } catch (err) {
      throw new InvalidDataError()
    }

    // Add other checks here...

    if (
      undefined === received.data ||
      (Array.isArray(received.data) && 0 === received.data.length)
    ) {
      throw new InvalidDataError('Resource contains no data.')
    } else if (
      Array.isArray(received.data) &&
      !received.data.every(el => el.type === received.data?.[0]?.type)
    ) {
      throw new InvalidDataError(
        'Every data entry must have the same resource type.'
      )
    }

    return {
      data: ForceArray(received.data),
      included: received.included ? ForceArray(received.included) : undefined,
    }
  }

  private static transformIncomingObject(
    jsonObjects: IJsonApiObject[]
  ): IDataObject[] {
    const dataObjects: IDataObject[] = []
    for (const jsonObject of jsonObjects) {
      const dataObject: IDataObject = {
        id: jsonObject.id,
        type: jsonObject.type,
      }

      if (undefined !== jsonObject.attributes)
        dataObject.attributes = jsonObject.attributes

      if (undefined !== jsonObject.relationships) {
        dataObject.relationships = {}

        for (const key in jsonObject.relationships) {
          const relEntities = jsonObject.relationships[key].data
          if (Array.isArray(relEntities)) {
            dataObject.relationships[key] = JsonApi.transformIncomingObject(
              relEntities
            )
          } else {
            dataObject.relationships[key] = JsonApi.transformIncomingObject([
              relEntities,
            ])[0]
          }
        }
      }

      dataObjects.push(dataObject)
    }

    return dataObjects
  }
}

/*
// The function that performs the check of the ignore list
function filterIgnored(this: any, key: string, value: any) {
  if (
    !Object.getOwnPropertyNames(Object.getPrototypeOf(this)).includes(
      'ignoreList' //Configs.IgnoreListName
    )
  )
    return value

  const ignore_list = Object.getOwnPropertyDescriptor(
    Object.getPrototypeOf(this),
    'ignoreList' //Configs.IgnoreListName
  )

  if (
    ignore_list &&
    Array.isArray(ignore_list.value) &&
    ignore_list.value.includes(key)
  ) {
    return undefined
  }

  return value
}
*/
function ForceArray<T>(obj: T | T[]): T[] {
  if (Array.isArray(obj)) {
    return obj
  }
  return [obj]
}
