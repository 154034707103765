import { Nullable } from '.'
import { IBaseDto, BaseDto } from './persistable'

export interface IInstitutionDetails extends IBaseDto {
  university: Nullable<String>
  department: Nullable<String>
}

export class InstitutionDetailsDto extends BaseDto
  implements IInstitutionDetails {
  university: Nullable<String>
  department: Nullable<String>
  className? = 'InstitutionDetails'

  constructor(init?: Partial<InstitutionDetailsDto>) {
    super()
    if (init) {
      Object.assign(this, init)
    }
  }
}
