export class DomainError extends Error {
  code: string
  status: number
  constructor(status?, code?, message?) {
    super(message)
    // Ensure the name of this error is the same as the class name
    this.name = this.constructor.name
    this.code = code
    this.status = status
  }
}

export class ResourceNotFoundError extends DomainError {
  constructor(resource?) {
    super(404, 'ERR_NOT_FOUND', `Resource ${resource} was not found.`)
  }
}

export class InvalidDataError extends DomainError {
  constructor(message?) {
    super(400, 'ERR_INVALID_DATA', message || `Data specified was invalid.`)
  }
}
