import { BaseDto, IBaseDto } from './persistable'
import { Nullable } from './utils'

export interface IBookCategory extends IBaseDto {
  name: Nullable<String>
  description: Nullable<String>
  parentCategory: Nullable<IBookCategory>
}

export class BookCategoryDto extends BaseDto implements IBookCategory {
  name: Nullable<String>
  description: Nullable<String>
  parentCategory: Nullable<BookCategoryDto>
  className? = 'BookCategory'

  constructor(init?: Partial<BookCategoryDto>) {
    super()
    if (init) {
      const { parentCategory, ...rest } = init
      Object.assign(this, rest)
      this.parentCategory =
        parentCategory && new BookCategoryDto(parentCategory)
    }
  }
}
