import { IUser, UserDto } from './user'
import { IComment, CommentDto } from './comment'
import { BaseDto, IBaseDto } from './persistable'
import { Nullable, NullableArray } from './utils'

export interface IAssignable {
  readonly code: Number
  phaseStartDate: Nullable<Date>
  phaseEndDate: Nullable<Date>
  assignedTo: NullableArray<IUser>
  signedOffBy: Nullable<IUser>
}

export interface IStep extends IAssignable, IBaseDto {
  notes: NullableArray<IComment>
  isCompleted: Nullable<Boolean>
  desiredDate: Nullable<Date>
}

export class StepDto extends BaseDto implements IStep {
  readonly code: Number
  notes: NullableArray<CommentDto>
  phaseStartDate: Nullable<Date>
  phaseEndDate: Nullable<Date>
  desiredDate: Nullable<Date>
  assignedTo: NullableArray<UserDto>
  signedOffBy: Nullable<UserDto>
  isCompleted: Nullable<Boolean>
  className? = 'Step'

  constructor(init?: Partial<StepDto>) {
    super()
    if (init) {
      const { notes, assignedTo, signedOffBy, ...rest } = init
      Object.assign(this, rest)
      this.notes = notes?.map(el => el && new CommentDto(el))
      this.assignedTo = assignedTo?.map(el => el && new UserDto(el))
      this.signedOffBy = signedOffBy && new UserDto(signedOffBy)
    }
  }
}
